import { apiDonation, apiDonationSave } from "../../api/ApiRequest";
import { DonatType, TitleTipe } from "../../page/PardonPage/data";
import styles from "./page.module.scss";
import { ChangeEvent, useEffect, useState } from "react";

function PardonModuleForm(props: any) {
  const [error, setError] = useState<any[]>([]);
  // const widgetKey = process.env.NEXT_PUBLIC_WIDGET_KEY;
  const funSetType = (el: string) => {
    props.setSeceltedType(el);
  };

  const setNames = () => {
    if (props.nameLangth.length < 10) {
      props.setNameLangth([
        ...props.nameLangth,
        { id: Math.random().toString(36).substring(2, 9) + "", value: "" },
      ]);
    }
  };

  const setValueInp = (event: ChangeEvent<HTMLInputElement>, id: string) => {
    let mass: any = [];
    const { value } = event.target;
    const isValid = /^[а-яА-ЯёЁ\s-]*$/.test(value);
    if (isValid || value === "") {
      if (value.length < 41) {
        props.nameLangth.map((item: any) => {
          if (item.id === id) {
            mass.push({
              id: id,
              value: event.target.value,
            });
          } else {
            mass.push(item);
          }
        });
        props.setNameLangth(mass);
      }
      setError([]);
    }
  };
  useEffect(() => {
    setError([]);
  }, [props.selectedItem]);

  const funDeleteItem = (id: string) => {
    props.setNameLangth([...props.nameLangth].filter((it) => it.id !== id));
  };
  let step = 0;
  const handleClick = (coust: number) => {
    // Настройки виджета
    // widget_key: "e2ebbf55-7794-4aa9-8907-1872c4cc3fbb",
    const options = {
      widget_key: "e2ebbf55-7794-4aa9-8907-1872c4cc3fbb", // Ваш ключ виджета
      amount: coust,
      description: "Благотворительное пожертвование",
      // payment_method: "sbp",
      // user_email: "email@mail.ru",
      // user_phone: "79031234567",
      merchant_payment_id: step,
      // test: 1, // Тестовый режим: 0 - нет, 1 - да
    };
    console.log("options", options);

    let M = new window.Mixplat(options);
    console.log("M", M);

    const data = {
      donatType: DonatType.findIndex((el) => el === props.selectedItem) + 1,
      titleType: TitleTipe.findIndex((el) => el === props.selectedType) + 1,
      names: props.nameLangth.map((el: any) => el.value),
      amount: coust / 100,
    };

    M.build();

    // Обработчики событий
    M.setSuccessCallback(() => {
      //! успешный платеж
      console.log(options.merchant_payment_id);
      if (options.merchant_payment_id === step) {
        apiDonationSave(data).then((res: any) => {
          console.log("res", res);
          step++;
          console.log("M2", M);
        });
      }
    });

    M.setFailCallback(() => {
      alert("Ошибка");
    });
  };

  const clickPomil = (e: any) => {
    console.log("e", e);
    let data = {};
    let error: any = [];
    props.nameLangth.map((el: any) => {
      if (el.value.length === 0) {
        error.push(el.id);
      }
    });

    if (error.length === 0) {
      data = {
        donatType: DonatType.findIndex((el) => el === props.selectedItem) + 1,
        titleType: TitleTipe.findIndex((el) => el === props.selectedType) + 1,
        names: props.nameLangth.map((el: any) => el.value),
      };
      console.log("data", data);
    } else {
      setError(error);
    }

    if (data && props.nameLangth.every((el: any) => el.value.length !== 0)) {
      apiDonation(data).then((res: any) => {
        console.log("res", res);
        if (res?.data?.amount) {
          handleClick(res?.data?.amount * 100);
        } else {
          alert("Произошла ошибка");
        }
      });
    }
  };

  return (
    <div className={styles.PardonModuleForm}>
      <div className={styles.boxItem}>
        <h3>{props.item.name}</h3>

        <div className={styles.typse}>
          {props.item.types.map((el: string, index: number) => (
            <span
              key={index + "k"}
              className={props.selectedType === el ? styles.typeActive : ""}
              onClick={() => funSetType(el)}
            >
              {el}
            </span>
          ))}
        </div>
        <div className={styles.containerFormContainer}>
          <div className={styles.containerFormInner}>
            {props.nameLangth.map((el: any) => (
              <div className={styles.inpbox} key={`${el.id}k`}>
                <input
                  type="text"
                  placeholder="Имя"
                  value={el.value + ""}
                  onChange={(event) => setValueInp(event, el.id)}
                  className={
                    error.includes(el.id)
                      ? `${styles.inpBoxInner} ${styles.error}`
                      : styles.inpBoxInner
                  }
                />
                {props.nameLangth.length > 1 && (
                  <img
                    onClick={() => funDeleteItem(el.id)}
                    src="/img/x.svg"
                    alt="img"
                  />
                )}
              </div>
            ))}
            {props.nameLangth.length < 10 && (
              <div className={styles.add}>
                <button onClick={setNames}>Добавить имя</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.bottombox}>
        <p>{props.item.price}</p>
        <button onClick={clickPomil}>Подать поминовение</button>
      </div>
    </div>
  );
}

export default PardonModuleForm;

import styles from "./page.module.scss";

function HomeModuleContacts() {
  return (
    <div className={styles.HomeModuleContacts}>
      <div className={styles.container} id="contacts">
        <h2>Всегда готовы помочь</h2>
        <div className={styles.box}>
          <div className={styles.left}>
            <img src="/img/hpMan.png" alt="img" />
          </div>
          <div className={styles.rigth}>
            <h3>Задать вопрос священнику</h3>
            <div className={styles.contacts}>
              <a href="tg://resolve?phone=79081925009">
                <img src="/img/telega.svg" alt="img" />
                +7 908 192 50 09
              </a>
              <a href="https://wa.me/79081925009">
                <img src="/img/waap.svg" alt="img" />
                +7 908 192 50 09
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeModuleContacts;

"use client";
import "./styles/globals.css";
import Home from "./app/home/page";
import { Route, Routes } from "react-router-dom";
import AboutComing from "./app/aboutComing/page";
import News from "./app/news/page";
import Schedule from "./app/schedule/page";
import TempleCalendar from "./app/templeCalendar/page";
import Pardon from "./app/pardon/page";
import Rights from "./app/rights/page";
import Stavros from "./app/stavros/page";
import Souls from "./app/souls/page";
import NewsMore from "./app/newsMore/page";
import Payment from "./module/Payment/Payment";
import { useSelector } from "react-redux";
import NotFounded from "./app/not-found";

export default function App() {
  const stateForm = useSelector((state: any) => state.mixplatSlice);
  return (
    <div>
      <div style={{ display: stateForm.showMixPlat ? "block" : "none" }}>
        <Payment />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutComing" element={<AboutComing />} />
        <Route path="/news" element={<News />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/templeCalendar" element={<TempleCalendar />} />
        <Route path="/pardon" element={<Pardon />} />
        <Route path="/rights" element={<Rights />} />
        <Route path="/stavros" element={<Stavros />} />
        <Route path="/souls" element={<Souls />} />
        <Route path="/newsMore" element={<NewsMore />} />
        <Route path="*" element={<NotFounded />} />
      </Routes>
    </div>
  );
}

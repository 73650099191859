import styles from "./page.module.scss";
import "./../../styles/globals.css";
import Header from "../../components/Header/page";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/page";

function NotFound() {
  return (
    <div className={styles.NotFound}>
      <Header />
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <h1>Страница не найдена</h1>
          <Link to="/">
            <button>На главную</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;

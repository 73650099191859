import { useEffect } from "react";
import HomeModuleAdress from "../../module/HomePage/HomeModuleAdress/page";
import HomeModuleContacts from "../../module/HomePage/HomeModuleContacts/page";
import HomeModuleInfo from "../../module/HomePage/HomeModuleInfo/page";
import HomeModuleTitle from "../../module/HomePage/HomeModuleTitle/page";
import HomePageConstruction from "../../module/HomePage/HomePageConstruction/page";
import styles from "./page.module.scss";

function HomePage() {
  return (
    <div className={styles.HomePage}>
      <HomeModuleTitle />
      <HomeModuleInfo />
      <HomePageConstruction />
      <HomeModuleContacts />
      <HomeModuleAdress />
    </div>
  );
}

export default HomePage;

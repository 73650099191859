import { combineReducers, configureStore } from "@reduxjs/toolkit";
import idSlice from "./Ids/Ids";
import mixplatSlice from "./mixplat/mixplat";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  idSlice: idSlice,
  mixplatSlice: mixplatSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["idSlice"],
  blacklist: ["mixplatSlice", "idSlice"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

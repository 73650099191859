import styles from "./page.module.scss";
import RootLayout from "../../Layout/layout";
import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { GetNews } from "../../api/ApiRequest";
import {
  setFilteredDate,
  setLen,
  setLengthMass,
  setNewsData,
} from "../../store/Ids/Ids";
import store from "../../store/store";
import NewsCard from "../../components/NewsPage/page";

function NewsPage() {
  const [isLoading, setIsLoading] = useState(false);
  // const [newsData, setNewsData] = useState([]);
  // const [filteredDate, setFilteredDate] = useState([]);
  // const [lengthMass, setLengthMass] = useState(5);
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.idSlice);
  useEffect(() => {
    if (state?.newsData?.length === 0) {
      setIsLoading(true);
      GetNews()
        .then((response: any) => {
          if (response?.status === 200) {
            const news = response?.data?.news.filter(
              (item: any) =>
                item.text !== "" &&
                item.text !== null &&
                item.text !== undefined
            );
            dispatch(setNewsData({ data: news }));
          }
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (state?.filteredDate?.length === 0) {
      dispatch(setFilteredDate({ data: state?.newsData?.slice(0, 5) }));
    }
    if (state?.filteredDate?.length < state?.lengthMass) {
      dispatch(setLen({ len: state?.filteredDate?.length }));
    }
  }, [state?.newsData]);

  useEffect(() => {
    if (state?.lengthMass === 0) {
      dispatch(setLen({ len: 5 }));
    }
    dispatch(
      setFilteredDate({ data: state?.newsData?.slice(0, state?.lengthMass) })
    );
  }, [state?.lengthMass]);

  // useEffect(() => {
  //   console.log("filteredDate", filteredDate);
  // }, [filteredDate]);

  const plusLength = () => {
    dispatch(setLengthMass());
  };

  return (
    <Provider store={store}>
      <div>
        <RootLayout>
          <div>
            <main className={styles.NewsPage}>
              <h1>Новости</h1>
              {Array.isArray(state?.filteredDate) &&
                !isLoading &&
                state?.filteredDate?.length > 0 &&
                state?.filteredDate?.map((el: any, index: number) => {
                  return (
                    <div key={index}>
                      <NewsCard dataNews={el} />
                    </div>
                  );
                })}

              {state?.newsData?.length === 0 && !isLoading && (
                <div>
                  <h2>Новостей нет</h2>
                </div>
              )}
              {isLoading && (
                <div className={styles.Loader}>
                  <span className={styles.loader}></span>
                </div>
              )}
              {state?.newsData?.length > 0 &&
                !isLoading &&
                state?.lengthMass < state?.newsData?.length && (
                  <div className={styles?.newData}>
                    <span onClick={plusLength}>Смотреть еще...</span>
                  </div>
                )}
            </main>
          </div>
        </RootLayout>
      </div>
    </Provider>
  );
}

export default NewsPage;

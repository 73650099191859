import styles from "./page.module.scss";

function HomeModuleInfo() {
  return (
    <div className={styles.HomeModuleInfo}>
      <div className={styles.uzor}>
        <img src="/img/uzor.svg" alt="img" />
      </div>

      <div className={styles.container}>
        <h2>Мы состоим из:</h2>
        <p>
          Храм пророка Илии построен в 2018 году по благословению митрополита
          Меркурия. Настоятелем назначен был иерей Борис Гущин, штатным
          священником иерей Павел Дудоладов. Первая литургия совершена в 2018
          году в праздник Богоявления. 15 августа 2023 года настоятелем назначен
          иерей Павел Дудоладов, а иерей Борис Гущин назначен настоятелем
          Георгиевского храма г. Таганрога и благочинным Таганрогского округа.
        </p>
        <div className={styles.imgBox}>
          <img src="/img/hp1n.png" alt="img" />
          <img src="/img/hp2n.png" alt="img" />
        </div>
      </div>
    </div>
  );
}

export default HomeModuleInfo;

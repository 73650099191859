import styles from "./page.module.scss";
import { useEffect, useState } from "react";
import { apiGetSchedule } from "../../api/ApiRequest";
import moment from "moment";
import { funFormatDateString } from "../../Functions";
import CalendarComponent from "../../components/CalendarComponent/page";
import MiniCalendarComponent from "../../components/MiniCalendarComponent/page";
import SchedulePlitka from "../../components/SchedulePlitka/page";

function SchedulePage() {
  const [sheduleData, setSheduleData] = useState<any>([]);
  const [selectedDateCalendar, setSelectedDateCalendar] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(
    new Date(selectedDateCalendar)
  );
  const [isLoaded, setIsLoaded] = useState(true);
  const funApiGetSchedule = () => {
    // const dat = "2024-10-10";
    setIsLoaded(true);
    const dat = funFormatDateString(selectedDateCalendar);
    apiGetSchedule(dat)
      .then((req: any) => {
        if (req?.status === 200) {
          let tamp = req.data.schedule;
          let mass: any = [];
          mass = tamp.map((entry: { info: any[] }) => {
            // Сортируем информацию по времени внутри каждого элемента `info`
            entry.info.sort((a, b) => {
              const timeA = new Date(`1970-01-01T${a.time}`);
              const timeB = new Date(`1970-01-01T${b.time}`);
              return timeA.getTime() - timeB.getTime();
            });
            return entry;
          });
          // Генерация массива всех дат текущей недели
          const currentDate = moment(dat); // Выбранная дата
          const endOfWeek = currentDate
            .clone()
            .add(-1, "days")
            .endOf("week")
            .add(1, "days"); // Воскресенье

          const weekDates = [];
          for (
            let date = currentDate.clone();
            date.isBefore(endOfWeek);
            date.add(1, "days")
          ) {
            weekDates.push(date.format("YYYY-MM-DD"));
          }

          // Добавляем недостающие даты
          const newMass = weekDates.map((date) => {
            const existingEntry = mass.find((item: any) => item.date === date);
            if (existingEntry) {
              return existingEntry; // Сохраняем данные, если они есть
            }
            // Добавляем недостающую дату с описанием "Нет богослужений"
            return {
              date,
              info: [
                {
                  id: `no-service-${date}`,
                  date,
                  time: "", // Или оставить пустым
                  description: "Нет богослужений",
                },
              ],
            };
          }) as {
            date: string;
            info: {
              id: string;
              date: string;
              time: string;
              description: string;
            }[];
          }[];

          setSheduleData(newMass);
          // setSheduleData(mass);
        }
        setIsLoaded(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  useEffect(() => {
    funApiGetSchedule();
  }, [selectedDateCalendar]);

  const funPlusStep = () => {
    // Определяем следующую дату понедельника
    const newSelectedDate = moment(selectedDateCalendar)
      .startOf("isoWeek") // устанавливаем на начало этого понедельника
      .add(1, "week"); // добавляем одну неделю, чтобы получить следующий понедельник
    // Получаем новый месяц и год из новой даты
    const newMonth = newSelectedDate.month(); // Месяц - 0 (январь) до 11 (декабрь)
    const newYear = newSelectedDate.year(); // Год
    // Обновляем выбранную дату
    setSelectedDateCalendar(newSelectedDate.toDate());
    // Обновляем activeStartDate новыми значениями года и месяца
    const updatedActiveStartDate = new Date(activeStartDate);
    updatedActiveStartDate.setFullYear(newYear);
    updatedActiveStartDate.setMonth(newMonth);
    setActiveStartDate(updatedActiveStartDate);
  };

  const funMinusStep = () => {
    // Определяем предыдущий понедельник
    const newSelectedDate = moment(selectedDateCalendar)
      .startOf("isoWeek") // Устанавливаем на начало этой недели (понедельник)
      .subtract(1, "week"); // Вычитаем одну неделю, чтобы получить предыдущий понедельник
    // Получаем новый месяц и год из новой даты
    const newMonth = newSelectedDate.month(); // Месяц - 0 (январь) до 11 (декабрь)
    const newYear = newSelectedDate.year(); // Год
    // Обновляем выбранную дату
    setSelectedDateCalendar(newSelectedDate.toDate());
    // Обновляем activeStartDate новыми значениями года и месяца
    const updatedActiveStartDate = new Date(activeStartDate);
    updatedActiveStartDate.setFullYear(newYear);
    updatedActiveStartDate.setMonth(newMonth);
    setActiveStartDate(updatedActiveStartDate);
  };

  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", { day: "numeric", month: "long" });
  };
  console.log("sheduleData", sheduleData);
  return (
    <div className={styles.SchedulePage}>
      <h2>Расписание богослужений</h2>
      <div className={styles.container}>
        <div className={styles.head}>
          <div className={styles.left}>
            <CalendarComponent
              selectedDateCalendar={selectedDateCalendar}
              setSelectedDateCalendar={setSelectedDateCalendar}
              activeStartDate={activeStartDate}
              setActiveStartDate={setActiveStartDate}
            />
          </div>
          <div className={styles.rigth}>
            <MiniCalendarComponent
              selectedDateCalendar={selectedDateCalendar}
              setSelectedDateCalendar={setSelectedDateCalendar}
              activeStartDate={activeStartDate}
              setActiveStartDate={setActiveStartDate}
              funMinusStep={funMinusStep}
              funPlusStep={funPlusStep}
            />
          </div>
        </div>
        <div className={styles.sedule_box}>
          {sheduleData.map((item: any, index: number) => (
            <SchedulePlitka key={index} date={item.date} item={item.info} />
          ))}
          {sheduleData.length === 0 && !isLoaded && (
            <div className={styles.noData}>
              <p>{formatDate(selectedDateCalendar) + ""} нет событий.</p>
            </div>
          )}
          {isLoaded && (
            <div className={styles.Loader}>
              <span className={styles.loader}></span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.bottonNavigagte}>
        <div className={styles.bottonNavigagteInner} onClick={funMinusStep}>
          <img src="/img/arrowNawigateCalendar.svg" alt="img" />
          <span>Предыдущая неделя</span>
        </div>
        <div className={styles.bottonNavigagteInner} onClick={funPlusStep}>
          <span>Следующая неделя</span>
          <img
            style={{ transform: "rotate(180deg)" }}
            src="/img/arrowNawigateCalendar.svg"
            alt="img"
          />
        </div>
      </div>
    </div>
  );
}

export default SchedulePage;

import styles from "./page.module.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { apiGetNewsId } from "../../api/ApiRequest";
import { Link, useNavigate } from "react-router-dom";
import StavrosSlider from "../../components/StavrosSlider/page";

function NewsPageMore() {
  type News = {
    createdAt: string;
    date: string;
    deletedAt: string;
    id: string;
    imgUrls: string[];
    ownerId: string;
    postId: string;
    text: string;
    title: string;
    updatedAt: string;
  };

  const store = useSelector((state: any) => state.idSlice);
  const navigate = useNavigate();
  useEffect(() => {
    if (!store.id) {
      navigate("/news");
    }
  }, [store]);

  const [data, setData] = useState<News>({
    createdAt: "",
    date: "",
    deletedAt: "",
    id: "",
    imgUrls: [],
    ownerId: "",
    postId: "",
    text: "",
    title: "",
    updatedAt: "",
  });

  const [isLoaded, setIsLoaded] = useState(true);

  async function checkImageAvailability(url: string) {
    try {
      const response = await fetch(url, { method: "HEAD" });
      return response.ok;
    } catch (error) {
      return false;
    }
  }

  async function filterImageUrls(imageUrls: string[]) {
    // Проверяем, что imageUrls определен и является массивом
    if (!Array.isArray(imageUrls)) {
      return []; // Возвращаем пустой массив, если нет валидных URL
    }
    const availabilityChecks = await Promise.all(
      imageUrls.map((url) => checkImageAvailability(url))
    );
    return imageUrls.filter((url, index) => availabilityChecks[index]);
  }

  const funGetImgs = () => {
    return data.imgUrls?.filter(
      (el) => el !== "" && el !== null && el !== undefined
    );
  };

  useEffect(() => {
    apiGetNewsId(store.id)
      .then(async (req) => {
        console.log("req", req);
        if (req?.status === 200) {
          let dat = req?.data?.news;
          dat.imgUrls = await filterImageUrls(dat.imgUrls);
          if (dat.imgUrls.length === 3) {
            dat.imgUrls = [...dat.imgUrls, dat.imgUrls[0]];
          }
          if (dat.imgUrls.length === 2) {
            dat.imgUrls = [...dat.imgUrls, ...dat.imgUrls];
          }
          setData(dat);
        }
        setIsLoaded(false);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, [store.id]);

  console.log("store.id", store?.id);
  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const FormattedText = (text: string) => {
    // Разбиваем текст на строки по <br /> или \n
    const lines = text?.split(/\n|<br\s*\/?>/);

    // Сохраняем первую строку, если она не превышает 100 символов
    const firstLine = lines[0];
    let updatedText = "";

    if (firstLine.length <= 100) {
      // Оборачиваем первую строку в <h2> и добавляем остальные строки
      updatedText += `<h2>${firstLine}</h2>`;
      updatedText += lines.slice(1).join("<br />");
    } else {
      // Если первая строка больше 100, просто соединяем все строки
      updatedText = lines.join("<br />");
    }

    // Форматируем ссылки и удаляем хэштеги
    updatedText = updatedText
      .replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" rel="noopener noreferrer">ссылка</a>'
      )
      .replace(
        /\[club(\d+)\|([^\]]+)\]/g,
        '<a href="https://vk.com/club$1" target="_blank" rel="noopener noreferrer">$2</a>'
      )
      .replace(
        /\[id(\d+)\|([^\]]+)\]/g,
        '<a href="https://vk.com/id$1" target="_blank" rel="noopener noreferrer">$2</a>'
      );
    // .replace(/#\S+/g, ""); // Удаление хештегов

    return updatedText;
  };
  const handleLinkClick = (id: string) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementPosition - 200,
        });
      }
    }, 200);
  };

  return (
    <div className={styles.NewsPageMoreContainer}>
      <div className={styles.NewsPageMore}>
        <div>
          <div className={styles.ButtonBack}>
            <Link to={`/news#${store.id}`}>
              <button onClick={() => handleLinkClick(store.id)}>
                <img src="/img/ArrowBack.svg" alt="img" />
                <p>Вернуться к новостям</p>
              </button>
            </Link>
          </div>
          {isLoaded ? (
            <div className={styles.Loader}>
              <span className={styles.loader}></span>
            </div>
          ) : (
            <div>
              <div className={styles.textInfo}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: FormattedText(data.text),
                  }}
                ></p>

                <div className={styles.date}>
                  <p>{formatDate(new Date(data.date))}</p>
                </div>
              </div>
              {funGetImgs()?.length > 1 && (
                <div className={styles.slider}>
                  <StavrosSlider images={funGetImgs()} />
                </div>
              )}
              {funGetImgs()?.length > 1 && (
                <div className={styles.imgsMobil}>
                  {funGetImgs() && funGetImgs().length > 1 && (
                    <div className={styles.imgsMobilInner}>
                      {funGetImgs()
                        .filter(
                          (url, index, self) => self.indexOf(url) === index
                        )
                        .map((el: string, index: number) => (
                          <img key={index} src={el} alt={`img-${index}`} />
                        ))}
                    </div>
                  )}
                </div>
              )}
              {funGetImgs()?.length === 1 && funGetImgs()[0] && (
                <div className={styles.video}>
                  <img src={funGetImgs()[0]} alt="img" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsPageMore;

import { createSlice } from "@reduxjs/toolkit";

const mixplatSlice = createSlice({
  name: "mixplatSlice",
  initialState: {
    showMixPlat: false,
  },

  reducers: {
    closeMixPlat(state) {
      state.showMixPlat = !state.showMixPlat;
    },
  },
});

export const { closeMixPlat } = mixplatSlice.actions;

export default mixplatSlice.reducer;

import { Link } from "react-router-dom";
import styles from "./page.module.scss";
import { useEffect, useRef, useState } from "react";

function Header() {
  const [showeMenu, setShoweMenu] = useState(false);

  const openMenu = () => {
    setShoweMenu(!showeMenu);
  };

  const refCalendar = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refCalendar.current && !refCalendar.current.contains(event.target)) {
        setShoweMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = () => {
    setTimeout(() => {
      const element = document.getElementById("contacts");
      if (element) {
        // Получаем позицию элемента относительно страницы
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        // Прокручиваем к элементу с учетом смещения
        window.scrollTo({
          top: elementPosition - 200, // смещение на 200 пикселей выше
          behavior: "smooth", // плавная прокрутка
        });
      }
    }, 200); // Задержка перед прокруткой
    openMenu();
  };

  const navLinks = [
    {
      name: "О приходе",
      link: "/aboutComing",
    },
    {
      name: "Новости",
      link: "/news",
    },
    {
      name: "Расписание богослужений",
      link: "/schedule",
    },
    {
      name: "Церковный календарь",
      link: "/templeCalendar",
    },
    {
      name: "Подать поминовение",
      link: "/pardon",
    },
  ];

  return (
    <div className={styles.Header}>
      <header>
        <div className={styles.headerInner}>
          <div className={styles.logoHome}>
            <Link to="/">
              <img src="/img/log.png" alt="img" />
            </Link>
          </div>
          <div>
            <ul>
              {navLinks.map((el, index) => (
                <li key={index}>
                  <Link to={el.link}>{el.name}</Link>
                </li>
              ))}
              <li>
                <Link to="/#contacts" onClick={handleLinkClick}>
                  Вопрос священнику
                </Link>
              </li>
            </ul>
            <img
              onClick={openMenu}
              className={styles.burger}
              src="/img/burger.svg"
              alt="img"
            />
          </div>
          {showeMenu && (
            <div
              ref={refCalendar as React.RefObject<HTMLDivElement>}
              className={styles.showMenu}
            >
              <Link onClick={openMenu} to="/">
                <img className={styles.logo} src="/img/log.png" alt="img" />
              </Link>

              <div className={styles.showMenuInner}>
                <p>
                  <Link onClick={openMenu} to="/">
                    Главная
                  </Link>
                </p>
                {navLinks.map((el) => (
                  <p>
                    <Link onClick={openMenu} to={el.link}>
                      {el.name}
                    </Link>
                  </p>
                ))}
                <p>
                  <Link to="/#contacts" onClick={handleLinkClick}>
                    Вопрос священнику
                  </Link>
                </p>
              </div>
              <div className={styles.close} onClick={openMenu}>
                <img src="/img/xx.svg" alt="img" />
              </div>
              <img
                className={styles.headerDisign}
                src="/img/HeaderDesignBg.svg"
              />
            </div>
          )}
          <div>
            <img
              className={styles.headerDisign}
              src="/img/HeaderDesignBg.svg"
            />
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;

import React from "react";
import styles from "./page.module.scss";
import { Link } from "react-router-dom";

function Footer() {
  const handleLinkClick = () => {
    setTimeout(() => {
      const element = document.getElementById("contacts");
      if (element) {
        // Получаем позицию элемента относительно страницы
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY;
        // Прокручиваем к элементу с учетом смещения
        window.scrollTo({
          top: elementPosition - 200, // смещение на 200 пикселей выше
          behavior: "smooth", // плавная прокрутка
        });
      }
    }, 200); // Задержка перед прокруткой
  };

  const navLinks = [
    {
      name: "О приходе",
      link: "/aboutComing",
    },
    {
      name: "Новости",
      link: "/news",
    },
    {
      name: "Подать поминовение",
      link: "/pardon",
    },
    {
      name: "Церковный календарь",
      link: "/templeCalendar",
    },
    {
      name: "Расписание богослужений",
      link: "/schedule",
    },

    {
      name: "Вопрос священнику",
      link: "/#contacts",
    },

    {
      name: "Правовая информация",
      link: "/rights",
    },
  ];

  return (
    <div className={styles.Footer}>
      {/* <script
        src="https://widgets.donation.ru/wloader/fb20bd9c-f8d5-4d1d-9266-fc4852c47148/wloader.js"
        async
      ></script>
      <script async src="https://cdn.mixplat.ru/widget/v3/widget.js"></script> */}
      <div className={styles.container}>
        <div className={styles.block1}>
          {navLinks.slice(0, 3).map((el) => (
            <p>
              <Link to={el.link}>{el.name}</Link>
            </p>
          ))}
        </div>
        <div className={styles.block2}>
          {navLinks.slice(3, 7).map((el) =>
            el.link === "/#contacts" ? (
              <p onClick={handleLinkClick}>
                <Link to={el.link}>{el.name}</Link>
              </p>
            ) : (
              <p>
                <Link to={el.link}>{el.name}</Link>
              </p>
            )
          )}
        </div>
        <h2>Храм Пророка Илии</h2>
      </div>
      <div className={styles.pravaInfo}>
        <div className={styles.pravaInfoBox1}>
          <p>
            МЕСТНАЯ РЕЛИГИОЗНАЯ ОРГАНИЗАЦИЯ ПРАВОСЛАВНОГО ПРИХОДА ИЛЬИНСКОГО
            ХРАМА ГОРОДА ТАГАНРОГА РОСТОВСКОЙ ОБЛАСТИ РОСТОВСКОЙ-НА-ДОНУ ЕПАРХИИ
            РУССКОЙ ПРАВОСЛАВНОЙ ЦЕРКВИ (МОСКОВСКИЙ ПАТРИАРХАТ)
          </p>
        </div>
        <div className={styles.pravaInfoBox2}>
          <p>
            ИНН/КПП: 6154115394/615401001
            <br />
            ОГРН: 1136100001982
            <br />
            Расчётный счёт: 40703810052090013737
            <br />
            Банк: ЮГО-ЗАПАДНЫЙ БАНК ПАО СБЕРБАНК
          </p>
        </div>
        <div className={styles.pravaInfoBox3}>
          <p>© Все права защищены</p>
        </div>
        <div className={styles.pravaInfoBox4}>
          <p>Телефон: +7 908 192 50 09</p>
          <p>Email: paveldudoladov@mail.ru</p>
          <p>
            347942, г. Таганрог, микрорайон
            <br /> Андреевский, улица Победы, 112
          </p>
        </div>
      </div>
      <div className={styles.pravanout}>
        <p>© Все права защищены</p>
      </div>
    </div>
  );
}

export default Footer;

import { closeMixPlat } from "../../../store/mixplat/mixplat";
import styles from "./page.module.scss";
import { useDispatch } from "react-redux";

function HomePageConstruction() {
  const dispach = useDispatch();
  return (
    <>
      <div className={styles.HomePageConstruction}>
        <div className={styles.container}>
          <h2>Процесс строительства</h2>
          <div className={styles.box}>
            <div className={styles.left}>
              <p>
                Сейчас ведётся строительство основного <br /> каменного храма.
              </p>
              <p>
                При храме работает военно-
                <br />
                патриотический спортивный <br />
                клуб "Ставрос".
              </p>
              <button onClick={() => dispach(closeMixPlat())}>
                Хочу помочь
              </button>
              <img className={styles.uzor} src="/img/uhp.svg" alt="img" />
            </div>
            <div className={styles.rigth}>
              <img src="/img/hp3n.png" alt="img" />
              <button
                className={styles.btnRigl}
                onClick={() => dispach(closeMixPlat())}
              >
                Хочу помочь
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePageConstruction;
